import { PageContentAtom } from '@justomx/atlas-components'

import './HomeList.page.css'
import { UserAndPassPage } from './UserAndPass/UserAndPass.page'
import { ScannerMolecule } from './ScanQR/ScanQR.page'
import { useHomeList } from './HomeList.hook'
import { useTranslation } from 'react-i18next'
import { ErrorType } from './HomeList.types'
import { toast } from 'react-toastify'
import type { TFunction } from 'i18next'
import LoaderAtom from 'components/atoms/Loader.atom'
import styled from 'styled-components'
import { FlexAtom } from 'components/atoms/Flex.atom'

const Content = styled(PageContentAtom)`
  padding: 40px 16px;
`

function errorHandler (t: TFunction<string, undefined>) {
  return function (e: string, translate = true) {
    if (!translate) {
      return toast.error(e)
    }

    if (e.includes(ErrorType.userNotFound)) {
      return toast.error(t('home.user-not-found'))
    } else if (e.includes(ErrorType.userLogin)) {
      return toast.error(t('home.user-login'))
    } else if (e.includes(ErrorType.badRequest)) {
      return toast.error(t('home.invalid-qr'))
    } else {
      console.error('[Error handler]: ', e)
      return toast.error(t('home.unknown-error'))
    }
  }
}

export const HomeListPage = () => {
  const { t } = useTranslation('global')
  const { loading, loginByUserPass, loginWithKeycloak, loginWithQR } =
    useHomeList(errorHandler(t))

  return (
    <Content>
      {loading && <LoaderAtom>{t('loader.message') + '.'}</LoaderAtom>}

      <FlexAtom $justifyContent="space-between" $height="100%">
        <UserAndPassPage
          onLogin={loginByUserPass}
          onLoginKeycloack={loginWithKeycloak}
        />
        <ScannerMolecule onScan={loginWithQR} />
      </FlexAtom>
    </Content>
  )
}
