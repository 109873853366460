import { AtlasBodyMain } from '@justomx/atlas-components'
import { useScannerReader } from 'hooks/scanner.hook'
import { FlexAtom } from 'components/atoms/Flex.atom'

interface ScannerProps {
  onScan: (scanned: string) => void
}

export const ScannerMolecule = (props: ScannerProps) => {
  const { onScan } = props

  useScannerReader(onScan)

  return (
    <FlexAtom $gap={16} $alignItems="center">
      <AtlasBodyMain style={{ textAlign: 'center' }}>
        O ingresa con código QR
      </AtlasBodyMain>
      <AtlasBodyMain style={{ textAlign: 'center', color: '#9A9A9A' }}>
        Encuentra el código buscando tu nombre en la tablet y escanéalo con este
        dispositivo.
      </AtlasBodyMain>
    </FlexAtom>
  )
}
